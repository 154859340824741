import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import head from 'lodash/head';
import InfoDropDown from '../dropDown/Information';
import faqSources from '../../sources/faqSources';
import api from '../../lib/api';

const FAQ = ({ className, title }) => {
  const [state, setState] = useState({ data: null, isLoading: true });
  const router = useRouter();

  useEffect(() => {
    const fetchData = async () => {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      // const response = await api.get(faqSources.root, {
      //   params: {
      //     url: window.location.pathname.replace(/^(\/en\/)|(\/en)/, '/'),
      //     fallback: router.pathname,
      //   },
      // });

      setState((prevState) => ({
        ...prevState,
        data: {
          content: [
            {
              question: 'Неужели продукты с фермы отличаются от магазинных?',
              answer:
                'Да, продукты с фермы и магазинные могут иметь существенные различия. Продукты с фермы часто более свежие, так как не проходят длительное хранение или обработку для удовлетворения требований поставок в магазины. Они также могут быть выращены с использованием более натуральных методов без использования большого количества химикатов, что влияет на их вкус и качество.',
            },
            {
              question: 'Неужели продукты с фермы отличаются от магазинных?',
              answer:
                'Они также могут быть выращены с использованием более натуральных методов без использования большого количества химикатов, что влияет на их вкус и качество. В то же время, магазинные продукты обычно проходят обработку для продления срока годности и удобства транспортировки, что может повлиять на их свойства и содержание питательных веществ.',
            },
            {
              question: 'Почему фермерские продукты дороже?',
              answer:
                'Фермерские продукты часто дороже по нескольким причинам. Во-первых, производство на фермах обычно менее масштабное по сравнению с промышленными производствами, что увеличивает стоимость производства. Фермеры обычно не могут себе позволить использовать экономии от масштаба в такой же степени, как крупные производители.',
            },
            {
              question: 'Могу ли я ознакомиться с документами на продукты?',
              answer:
                'Конечно, в большинстве стран потребители имеют право на доступ к информации о продуктах, включая их состав, происхождение и сертификации. Эта информация обычно предоставляется на этикетках продуктов или может быть запрошена у производителя или продавца.',
            },
          ],
        },
        isLoading: false,
      }));
    };

    fetchData();
  }, [router.asPath.split('?')[0]]);

  if (state.isLoading || !state.data || !state.data.content) {
    return null;
  }

  return (
    <div>
      <div itemScope="itemscope" itemType="https://schema.org/Question">
        {state.data.content.map((faq) => (
          <InfoDropDown className={className} label={faq.question} itemprop="name">
            <div itemProp="acceptedAnswer" itemScope="itemscope" itemType="https://schema.org/Answer">
              <div itemProp="text">{faq.answer}</div>
            </div>
          </InfoDropDown>
        ))}
      </div>
    </div>
  );
};

FAQ.defaultProps = {
  className: null,
  title: null,
};

FAQ.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default FAQ;
