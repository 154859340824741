import React from 'react';
import ContentLayout from '../Layout/ContentLayout/ContentLayout';
import styles from './OurPromises.module.scss';

const information = [
  {
    title: 'Прямые отношения с производителем',
    subtitle:
      'Позволяет нам контролировать качество товаров и обеспечивать Вам доступ к свежим продуктам сразу после сбора урожая',
  },
  {
    title: 'Экологическая ответственность',
    subtitle:
      'Мы заботимся о природе. Продукция использующая нашими фермерами выражена только натуральным способом и не имеет никаких ГМО и вредных нитратов',
  },
  {
    title: 'Гарантия свежести',
    subtitle: 'В случае доставки продукта в испорченном виде предоставим Вам вовзраст денежных средств',
  },
  {
    title: 'Персонализированный подход',
    subtitle:
      'Учитываем все ваши предпочтения, потребности. Наш каталог обновляется и ориентируется на вас чтобы вы могли получать свежую продукцию вовремя',
  },
];

const OurPromises = () => {
  return (
    <ContentLayout className={styles.OurPromisesWrapper}>
      <div className={styles.OurPromisesTitle__container}>
        <div className={styles.OurPromisesTitle}>ЛУЧШИЕ ФЕРМЕРЫ НА ОДНОЙ ПЛОЩАДКЕ</div>
        <div className={styles.OurPromisesSubTitle}> Контроль качества</div>
      </div>
      <div className={styles.OurPromisesInfoBlock}>
        {information.map((info) => (
          <div className={styles.OurPromisesInfoBlock__items} key={info.title}>
            <div className={styles.OurPromisesInfoBlock__icon}>
              <img src="/icons2/jackdaw__tbebest__block.svg" alt="jackdaw"></img>
            </div>
            <div className={styles.OurPromisesInfoBlock__container}>
              <div className={styles.OurPromisesInfoBlock__title}>{info.title}</div>
              <div className={styles.OurPromisesInfoBlock__subTitle}>{info.subtitle}</div>
            </div>
          </div>
        ))}
      </div>
    </ContentLayout>
  );
};

export default OurPromises;
