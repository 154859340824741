import React from 'react';
import styles from './PopularCategories.module.scss';

import cn from 'classnames';
import Link from '../Link';
import routes from '../../helpers/routes';

const PopularCategories = () => {
  return (
    <div className={styles.popularCategoriesWrapper}>
      <div className={styles.popularCategoriesTitle}>
        ПОПУЛЯРНЫЕ КАТЕГОРИИ <br></br> ТОВАРОВ
      </div>

      <div className={styles.popularCategoriesTitleDesktop}>ПОПУЛЯРНЫЕ КАТЕГОРИИ</div>

      <div className={styles.popularAssortment}>
        <Link
          href={routes.productsCategory('molochnye-produkty-yajco')}
          className={cn(styles.popularAssortmentItem, styles.popularAssortmentMilk)}
        >
          <div className={styles.popularAssortmentSubTitle}> Молочные продукты, яйцо </div>
          <img
            src={`/icons2/milk_backgroundLg.png`}
            alt="background"
            className={styles.popularAssortmentBackground}
          ></img>
        </Link>
        <Link
          href={routes.productsCategory('myaso-ptica-krolik')}
          className={cn(
            styles.popularAssortmentItem,

            styles.popularAssortmentMeat,
          )}
        >
          <div className={styles.popularAssortmentSubTitle}> Мясо, птица, шашлык </div>
          <img
            src={`/icons2/meat__backroundLg.png`}
            alt="background"
            className={styles.popularAssortmentBackground}
          ></img>
        </Link>
        <Link
          href={routes.productsCategory('ovoshi-frukty-yagody-i-zelen')}
          className={cn(
            styles.popularAssortmentItem,

            styles.popularAssortmentVegetables,
          )}
        >
          <div className={styles.popularAssortmentSubTitle}> Овощи, фрукты, ягоды и зелень </div>
          <img
            src={`/icons2/vegetables-fruits__backgroundLg.png`}
            alt="background"
            className={styles.popularAssortmentBackground}
          ></img>
        </Link>
        <Link
          href={routes.productsCategory('hleb-i-vypechka')}
          className={cn(
            styles.popularAssortmentItem,

            styles.popularAssortmentBread,
          )}
        >
          <div className={cn(styles.popularAssortmentSubTitle)}>Хлеб и выпечка</div>
          <picture>
            <source media="(min-width:992px )" srcset="/icons2/bread__backgroundLg.png"></source>
            <img
              src={`/icons2/bread__backgroundSm.png`}
              alt="background"
              className={styles.popularAssortmentBackground}
            ></img>
          </picture>
        </Link>
        <Link
          href={routes.productsCategory('siry')}
          className={cn(
            styles.popularAssortmentItem,

            styles.popularAssortmentCheese,
          )}
        >
          <div className={styles.popularAssortmentSubTitle}> Сыры </div>
          <img
            src={`/icons2/cheese__backgroundLg.png`}
            alt="background"
            className={styles.popularAssortmentBackground}
          ></img>
        </Link>
        <Link
          href={routes.productsCategory('kolbasnye-izdeliya')}
          className={cn(styles.popularAssortmentItem, styles.popularAssortmentSausages)}
        >
          <div className={styles.popularAssortmentSubTitle}> Колбасные изделия </div>
          <img
            src={`/icons2/sausages__backgroundLg.png`}
            alt="background"
            className={styles.popularAssortmentBackground}
          ></img>
        </Link>

        <Link
          href={routes.products}
          className={cn(styles.popularCatalogContainer, styles.popularCatalogDesktop)}
        >
          <div className={styles.popularCatalogSubTitle}>Каталог</div>
          <picture>
            <source
              media="(min-width:992px )"
              srcset="/icons2/catalog-setVegetables__backgroundLg.png"
            ></source>
            <img
              src="/icons2/catalog-setVegetables__backgroundSm.png"
              className={styles.CatalogBackground}
              alt="background"
            ></img>
          </picture>
        </Link>
      </div>
    </div>
  );
};

export default PopularCategories;
