import React from 'react';
import styles from './BestOffer.module.scss';
import ContentLayout from '../Layout/ContentLayout';
import ProductCard from '../ProductCard';

const BestOffer = (props) => {
  const { products } = props;

  return (
    <div className={styles.bestOfferWrapper}>
      <ContentLayout>
        <div className={styles.BestOfferTitle}>ЛУЧШИЕ ПРЕДЛОЖЕНИЯ</div>
        <div className={styles.bestOfferContainer}>
          {products.data.map((product) => (
            <div key={product.id} className={styles.ProductCardIndents}>
              <ProductCard className={styles.card} data={product} inSlider />
            </div>
          ))}
        </div>
      </ContentLayout>
    </div>
  );
};

export default BestOffer;
