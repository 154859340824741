import React, { useEffect } from 'react';
import Navigation from '../../Navigation';
import ProductCard from '../../ProductCard';
import Footer from '../../Footer';
import SeoHead from '../../SeoHead';
import Subscribe from '../../Subscribe';
import styles from './IndexPage.module.scss';
import PromotionNews from '../../PromotionsNews/PromotionNews';
import ContentLayout from '../../Layout/ContentLayout/ContentLayout';
import Authorization from '../../Authorization';
import FAQ from '../../FAQComponent/FAQComponent';
import OurPromises from '../../OurPromises/OurPromises';
import BestOffer from '../../BestOffer/BestOffer';
import GEO from '../../GEO';
import ModalDeliveryDay from '../../ModalDeliveryDay';
import NavigationMobile from '../../Navigation/NavigationMobile';
import MobileNavigationTabs from '../../MobileNavigationTabs/MobileNavigationTabs';
import FarmerRegistration from '../../Farmers/FarmerRegistration/FarmerRegistration';
import PopularCategories from '../../PopularCategories/PopularCategories';
import SwiperForFarmers from '../../OurFarmers/SwiperForFarmers';
import SeoText from '../../SeoText';
import { getCookie } from '../../../lib/cookie';
import { COOKIE } from '../../../constants/COOKIE';
import Emmiter from '../../../lib/emmiter';
import EVENTS from '../../../constants/EVENTS';
import { useRouter } from 'next/router';

const IndexPage = (props) => {
  const { stores, bestProducts, discountProducts, mainBanner, seo } = props;
  const router = useRouter();
  const meta = {
    ...seo,
    breadcrumbList: [
      {
        position: '1',
        name: 'Главная',
        item: 'https://yfermera.ru',
      },
    ],
  };

  useEffect(() => {
    const deliveryAddress = getCookie(COOKIE.DELIVERY_ADDRESS_NAME);
    const closeGeoSessionStorage = sessionStorage.getItem('closeGeo');
    if (!deliveryAddress && !closeGeoSessionStorage && !router?.query?.login) {
      Emmiter.emit(EVENTS.OPEN_GEO_MODAL);
    }
  }, []);

  return (
    <div className={styles.page}>
      <SeoHead seo={meta} />
      <MobileNavigationTabs />
      <GEO />
      <FarmerRegistration></FarmerRegistration>
      <Authorization />
      <NavigationMobile></NavigationMobile>
      <ModalDeliveryDay />
      <div className={styles.header}>
        <Navigation />
        <div className={styles.headerWrap}>
          <PromotionNews mainBanner={mainBanner} />
        </div>
        <ContentLayout>
          <PopularCategories />
        </ContentLayout>
      </div>
      {discountProducts.count > 0 && (
        <div className={styles.wrapWhite}>
          <h3 className={styles.title}>ТОВАРЫ ПО АКЦИИ</h3>
          <ContentLayout className={styles.carouselContainer}>
            <div className={styles.carousel}>
              {discountProducts.data.map((product) => (
                <div className={styles.ProductCardIndents} key={product.name}>
                  <ProductCard className={styles.card} data={product} inSlider />
                </div>
              ))}
            </div>
          </ContentLayout>
        </div>
      )}
      <ContentLayout>
        <SwiperForFarmers data={stores.data}></SwiperForFarmers>
      </ContentLayout>

      {bestProducts.count > 0 && <BestOffer products={bestProducts} />}
      <OurPromises />

      <ContentLayout className={styles.FAQContainer}>
        <div className={styles.FAQTitle}>ВОПРОС-ОТВЕТ </div>
        <FAQ></FAQ>
      </ContentLayout>

      <div className={styles.subscribe}>
        <Subscribe />
      </div>
      <ContentLayout>
        <SeoText seo={seo} />
      </ContentLayout>
      <Footer />
    </div>
  );
};

export default IndexPage;
