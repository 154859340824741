import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import PlusIcon from '../../../../public/icons2/plus_faq.svg';
import Button from '../../Button';
import styles from './InfoDropDown.module.scss';

const InfoDropDown = (props) => {
  const { label, children, className, ...rest } = props;

  const [isDropped, toggleDropDown] = useState(false);

  const onOff = () => {
    toggleDropDown(!isDropped);
  };

  const infoDropDownCn = cn(styles.contain, {
    [className]: Boolean(className),
    [styles.active]: isDropped,
  });

  const controlBtnCn = cn(styles.controlBtn, { [styles.activeBtn]: isDropped });
  return (
    <div className={infoDropDownCn}>
      <Button className={controlBtnCn} onClick={onOff} variant="transparent">
        <div className={styles.inner}>
          <span {...rest}>{label}</span>
          {!isDropped && (
            <div className={styles.dropDownBtn}>
              <PlusIcon />
            </div>
          )}
          {isDropped && (
            <div className={styles.dropDownBtn}>
              <PlusIcon className={styles.rotate} />
            </div>
          )}
        </div>
      </Button>
      {isDropped && <div className={styles.content}>{children}</div>}
    </div>
  );
};

InfoDropDown.defaultProps = {
  className: null,
};

InfoDropDown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default InfoDropDown;
