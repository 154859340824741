import React, { useEffect, useRef, useState } from 'react';
import styles from './SwiperForFarmers.module.scss';
import Button from '../../Button/Button';
import cn from 'classnames';
import ProductCard from '../../ProductCard/ProductCard';

import Link from '../../Link';
import routes, { routesPatterns } from '../../../helpers/routes';
import Pagination from '../../Pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';

const SwiperForFarmers = (props) => {
  const { data, isShowBlock, classPersonalArea, farmerCard } = props;
  const swiperRef = useRef(null);

  useEffect(() => {
    let swiperInstance = swiperRef.current?.swiper;

    if (!swiperInstance) {
      return;
    }

    swiperInstance.allowTouchMove = false;
    swiperInstance.update();

    function checkScreenSize() {
      if (window.innerWidth >= 768) {
        swiperInstance.allowTouchMove = true;
        swiperInstance.update();
      } else {
        swiperInstance.allowTouchMove = false;
        swiperInstance.update();
      }
    }

    window.addEventListener('resize', checkScreenSize);
    checkScreenSize();

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  return (
    <div className={`${classPersonalArea ? styles.personalAreaWrapper : styles.ourFarmersWrapper}`}>
      <div className={styles.swiperWrapper}>
        <div className={styles.headerWrapper}>
          {' '}
          <div className={styles.ourFarmersHeader}>
            <div className={styles.headerLeftContainer}>
              <div className={styles.ourFarmersHeaderTitle}>НАШИ ФЕРМЕРЫ</div>
              <Link href={routes.farmers} className={styles.ourFarmersHeaderSubTitle}>
                Все фермеры
              </Link>
            </div>
          </div>{' '}
        </div>

        <Swiper
          modules={[Navigation, Pagination, Scrollbar, EffectFade]}
          speed={1400}
          spaceBetween={50}
          slidesPerView={1}
          navigation={true}
          ref={swiperRef}
          allowTouchMove={false}
        >
          {data.map((farmerItem, i) => (
            <SwiperSlide key={farmerItem.id}>
              <div className={styles.swiperBlock}>
                {' '}
                <div
                  key={farmerItem.id}
                  className={`${
                    classPersonalArea
                      ? styles.personalAreaContainer
                      : styles.ourFarmersMainContainer
                  }`}
                >
                  {isShowBlock ? (
                    <div className={styles.personalAreaTitleBlock}>
                      <div className={styles.farmersPersonalAreaBlock}>
                        <img
                          src="/icons2/photo-farmer.png"
                          alt="fotoFarmers"
                          className={styles.farmerFoto}
                        ></img>
                        <div className={styles.farmersInfoBlock}>
                          <div className={styles.farmerNameContainer}>
                            <Link
                              className={styles.farmerName}
                              href={routesPatterns.farmer}
                              as={`${routes.farmer(farmerItem.id)}`}
                            >
                              {farmerItem.name}
                            </Link>
                            <Button variant="transparent">
                              <img src="/icons2/jacdaw-right-small.svg"></img>
                            </Button>
                          </div>

                          <div className={styles.farmerGeolocation}>827 товаров</div>
                        </div>
                      </div>
                      <Button variant="transparent">
                        <img src="/icons2/heart-like_ourfarmers_red.svg"></img>
                      </Button>
                    </div>
                  ) : null}
                  {isShowBlock ? (
                    <div className={styles.deliveryTermsContainer}>
                      <div className={styles.deliveryTermsItem}>Заказ от 2 000 ₽</div>
                      <div className={styles.deliveryTermsItem}>Стоимость доставки 500 ₽</div>
                      <div className={styles.deliveryTermsItem}>Бесплатная доставка от 3 000 ₽</div>
                    </div>
                  ) : null}

                  {isShowBlock ? null : (
                    <div className={`${styles.ourFarmersMainTitleBlock}`}>
                      <div className={styles.ourFarmersNameWrapper}>
                        <Link
                          href={routesPatterns.farmer}
                          as={`${routes.farmer(farmerItem.slug)}`}
                          className={styles.ourFarmersFarmerName}
                        >
                          {farmerItem.name}
                        </Link>

                        <div className={styles.ourFarmersReviews}>
                          <img src="/icons2/yellow-star.svg" alt="star"></img> {farmerItem.rating}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className={styles.OurFarmersProduct}>
                    {farmerItem.products.map((product) => (
                      <div
                        key={product.id}
                        className={cn(styles.ProductCardIndents, {
                          [styles.farmerCard]: farmerCard,
                        })}
                      >
                        <ProductCard key={product.id} className={styles.card} data={product} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SwiperForFarmers;
