import React from 'react';
import styles from './PromotionNews.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, EffectFade } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import ContentLayout from '../Layout/ContentLayout/ContentLayout';
import Link from '../Link';

const PromotionNews = ({ mainBanner }) => {
  const { data } = mainBanner;

  return (
    <div className={styles.PromotionNewsBlock}>
      <ContentLayout>
        <div className={styles.NewsBannersDesktop}>
          <Swiper
            modules={[Navigation, Pagination, EffectFade, Scrollbar]}
            speed={1400}
            spaceBetween={50}
            navigation
            pagination={{
              clickable: true,
              renderBullet: function (index, className) {
                return `<span class="swiper-pagination-bullet"></span>`;
              },
            }}
            slidesPerView={1}
          >
            {data.map((banner, i) => (
              <SwiperSlide key={i}>
                <Link href={banner.link}>
                  <>
                    <div className={styles.NewsBlock}>
                      <div>
                        <div className={styles.navigationButtons}></div>
                      </div>
                    </div>
                    <div
                      className={styles.MainBannersDesktop}
                      style={{ backgroundImage: `url(${banner.image.source})` }}
                    >
                      <div className={styles.mainBannerInfo}>
                        <div className={styles.mainBannerTitle}>{banner.title}</div>
                      </div>
                    </div>
                  </>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={styles.NewsBottomBlock}></div>
          {/* <div className={styles.rightBlockNewsDesktopContainer}>
            <div className={styles.rightBlockNewsDesktop}>
              <div className={cn(styles.smallNewsBanner, styles.firstBunner)}>
                <div className={styles.BlockInfo}>
                  <div className={styles.BlockTitle}>Скидка на первый заказ</div>
                  <div className={styles.BlockText}>используй промокод</div>
                  <img
                    className={styles.firstBunnerIllustration}
                    src="headers/illustration-apple-desktop.svg"
                  ></img>
                </div>
                <div className={styles.promotionPromocode}>ЯГОДЫ</div>
              </div>
              <div className={cn(styles.smallNewsBanner, styles.secondBunner)}>
                <div className={styles.BlockInfo}>
                  <div className={styles.BlockTitle}>Новинки</div>
                  <div className={styles.BlockText}>Свежие сезонные ягоды</div>
                </div>
              </div>
              <div className={cn(styles.smallNewsBanner, styles.thirdBunner)}>
                <div className={styles.BlockInfo}>
                  <img src="headers/pink-shape.png" alt="fructShape"></img>
                  <div className={styles.BlockTitle}>Суперцены</div>
                  <div className={styles.BlockText}>Скидки до 37%</div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </ContentLayout>
    </div>
  );
};

export default PromotionNews;
