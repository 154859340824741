import React from 'react';
import { seoFormatter } from '../../helpers/seoFormatter';
import styles from './SeoText.module.scss';

const SeoText = (props) => {
  const { seo, category, name, subcategory, manufacturer, price, annotate } = props;

  return (
    <>
      {seo.titleH2 && (
        <h2
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: seoFormatter({
              text: seo.titleH2,
              category,
              subcategory,
              name,
              manufacturer,
              price,
              annotate,
            }),
          }}
        ></h2>
      )}
      {seo.text && (
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: seoFormatter({
              text: seo.text,
              category,
              subcategory,
              name,
              manufacturer,
              price,
              annotate,
            }),
          }}
        ></div>
      )}
    </>
  );
};

export default SeoText;
