import React from 'react';
import cn from 'classnames';
import ReactPagination from 'react-paginate';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import VectorLeftIcon from '../../../public/icons/vector-left-big.svg';
import VectorRightIcon from '../../../public/icons/vector-right-big.svg';
import DotsIcon from '../../../public/icons/dots.svg';
import styles from './Pagination.module.scss';

const Pagination = (props) => {
  const { meta, onChange } = props;

  const handlePageClick = (page) => {
    const selectedPage = page.selected + 1;
    onChange(selectedPage);
    window.scrollTo(0, 0);
  };

  if ((meta && meta.lastPage < 2) || isEmpty(meta)) {
    return null;
  }

  return (
    <div className={styles.wrap}>
      <ReactPagination
        previousLabel={<VectorLeftIcon />}
        nextLabel={<VectorRightIcon />}
        breakLabel={<DotsIcon />}
        containerClassName={styles.contain}
        pageLinkClassName={styles.item}
        previousLinkClassName={styles.item}
        nextLinkClassName={styles.item}
        breakClassName={cn(styles.item, styles.breakItem)}
        onPageChange={handlePageClick}
        activeLinkClassName={styles.active}
        pageCount={meta.lastPage}
        initialPage={meta.page - 1}
        forcePage={meta.page - 1}
        disableInitialCallback
      />
    </div>
  );
};

Pagination.propTypes = {
  meta: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
};

export default Pagination;
