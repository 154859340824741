import React from 'react';
import { Form, Field } from 'react-final-form';
import { NotificationManager } from 'react-notifications';
import VectorRightIcon from '../../../public/icons/vector-right.svg';
import subscribeSources from '../../sources/subscribeSources';
import { composeValidators, required, validEmail } from '../../helpers/validations';
import api from '../../lib/api';
import Input from '../Input';
import Button from '../Button';
import styles from './Subscribe.module.scss';
import ContentLayout from '../Layout/ContentLayout';

let finalForm;

const Subscribe = () => {
  const subscribe = (data) =>
    data
      ? api.post(subscribeSources.root, data).then(() => {
          NotificationManager.success('Успешно подисаны');
          finalForm.restart();
        })
      : Promise.resolve();

  return (
    <ContentLayout>
      <div className={styles.container}>
        <div className={styles.title}>
          Получайте последние новости о новых продуктах и предстоящих распродажах.
        </div>
        <Form
          validate={(values) => {
            const errors = {};
            const error = composeValidators(required(), validEmail)(values.email);

            if (error) {
              errors.email = error;
            }

            return errors;
          }}
          onSubmit={subscribe}
          render={({ handleSubmit, form }) => {
            finalForm = form;
            return (
              <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.input}>
                  <Field
                    name="email"
                    render={({ input, meta }) => (
                      <Input placeholder="Ваша почта" meta={meta} {...input} />
                    )}
                  />
                </div>
                <Button className={styles.submit} size="lg" fluid rounded uppercase type="submit">
                  Подписаться
                  <VectorRightIcon />
                </Button>
              </form>
            );
          }}
        />
      </div>
    </ContentLayout>
  );
};

export default Subscribe;
